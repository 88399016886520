import React, {useEffect} from "react";
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import toast from 'react-hot-toast';
import {existOperation,existDevelopment, getMessageWhatsapp, getPhonesText, makeItemsNav} from '../helpers/helper.rendering'
import { useLocation } from "@reach/router";

import {newSletterTokko,updateFields} from '../redux/contactDucks'
// import logo from "../images/logo.png"
// import logo from "../images/new-logo.png"
import LogoFooterSVG from "../images/logo-header-girard.svg"
import logo1 from "../images/CIA.png"
import logo2 from "../images/SOM.png"


import { Helmet } from "react-helmet"

const Footer = ({filters,api_key,developments,development,property,novelties,dispatch}) => {


  const [open,setOpen] = useState(false)
  const isBrowser = typeof window !== "undefined"
  const location = useLocation()
  const pathname = location.pathname.split("/")[1]

  const [flag,setFlag] = useState(false)

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        logo
        global_email
        fiscal_data
        bot_cliengo
        global_whatsapp
        global_phone
        global_broker_id
        attention
        keys{
            captcha
        }
        social{
          facebook
          instagram
          linkedin
          youtube
          twitter
          tiktok
        }
        branch_office{
          name
          address
          address_note
          city
          region
          broker_id
          contact {
            phone
            mail
            whatsapp
          }
        }
        sections {
          footer {
              title_contact
              title_social
              title_newsletter
              title_office
              title_matricula
              button_contact {
                  link
                  value
              }
          }
        }
      }
  }`) 
  const [disabled,setDisabled] = useState(true)
  const [datos, setDatos] = useState({
    email: "",
  });
  const handleInputChange = (e) => {
      setDatos({
        ...datos,
        [e.target.name]: e.target.value,
      });
  };
  const sendForm = (e) => {
    e.preventDefault();
    dispatch(newSletterTokko())
    document.getElementById("formNewsletter").reset();
    return toast.success("Te suscribiste a nuestro newsletter correctamente.") 
  }
  // Función que devuelve el año actual
  const getCurrentYear = () => {
    const date = new Date().getFullYear()
    return date;
  }
    
  useEffect(() => {
    const update = () => {
        dispatch(updateFields(datos));
    };
    update();
    if(!realEstate?.keys?.captcha){
      setDisabled(false)
    }
    else{
        setDisabled(true)
    }
  }, [datos]);

  const callCliengo = () => {
    // hide cliengo btn on: venta | alquiler | temporario
    if (pathname === 'venta' 
    || pathname === 'alquiler' 
    || pathname === 'temporario') {
      return null
    } else {
      if(!flag){
        // console.log('callCliengo');
        var ldk = document.createElement('script');
        ldk.type = 'text/javascript';
        ldk.async = true;
        ldk.src = realEstate.bot_cliengo;
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ldk, s);
        setFlag(true)
      }
    }
  }
    
    return realEstate.sections ? (
      <>
        { isBrowser && 
          <Helmet>
            { realEstate.bot_cliengo ? callCliengo() : null }
          </Helmet> }
        <div className="sup-footer">
            <div className="container-fluid">
            <div className="fixed-bar position-fixed">
                  {/* <>
                    <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+541158335255" } target="_blank" className={"branch-btn contact-btn d-none d-lg-flex " + (open ? 'open' : ' ')}>DIV. <br /> RES.</a>
                    <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+541158335255" } target="_blank" className={"branch-btn contact-btn d-flex d-lg-none " + (open ? 'open' : ' ')}>DIV. <br /> RES.</a>
                    <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+541141582902" } target="_blank" className={"branch-btn contact-btn d-none d-lg-flex " + (open ? 'open' : ' ')}>DIV. <br /> COM.</a>
                    <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+541141582902" } target="_blank" className={"branch-btn contact-btn d-flex d-lg-none " + (open ? 'open' : ' ')}>DIV. <br /> COM.</a>
                    <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+541132080445" } target="_blank" className={"branch-btn contact-btn d-none d-lg-flex " + (open ? 'open' : ' ')}>DIV. <br /> ALQ.</a>
                    <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+541132080445" } target="_blank" className={"branch-btn contact-btn d-flex d-lg-none " + (open ? 'open' : ' ')}>DIV. <br /> ALQ.</a>
                  </> */}
                  <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development,property) + "&phone=" + realEstate.global_whatsapp.replaceAll('-','').replaceAll(' ','')} target={"_blank"} className="icon-whatsapp-o contact-btn d-flex"><div className="pulse-border"></div></a>
                  {/* <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+541132080445"} target={"_blank"} className="icon-whatsapp-o contact-btn d-flex d-lg-none"><div className="pulse-border"></div></a> */}
              </div>

              <div className="row">
                <div className="col-12">
                    <div className="row">
                      <div className="col-lg-4 order-1 order-lg-1">
                        <div className="item-contact row">
                          <h5 className="col-6 col-lg-12">{realEstate.sections.footer.title_contact}:</h5>
                          <div className="col-6 col-lg-12">
                            {/* <h4>Central</h4> */}
                            <a className="hover-service-link hover-link" target="_blank" 
                            href={`mailto:${realEstate.global_email}`}>{realEstate.global_email}</a>
                            <p>Tel. {realEstate.global_phone[0]}</p>
                            <p>Whatsapp <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp() + "&phone=" + realEstate.global_whatsapp.replaceAll('-','').replaceAll(' ','')} target="_blank" className="hover-service-link hover-link d-inline">{realEstate.global_whatsapp}</a></p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xxl-3 order-2 order-lg-2 findus-section">
                        <div className="item-contact row">
                          <h5 className="col-6 col-lg-12">ENCONTRANOS:</h5>
                          {realEstate.branch_office.length > 0 &&
                            realEstate.branch_office.map((item,index) => (
                                <div className={"branch" + (index > 0 ? " col-6 offset-6 col-lg-12 offset-lg-0 d-flex flex-column" : " col-6 col-lg-12")}>
                                    {/* <h4 className={(index > 0 ? " mt-4" : "")}>{item.name}:</h4> */}
                                    <div className="d-block">
                                        <p>{item.address} {item.address_note ? <span className="note">{item.address_note}</span> : null}</p>
                                        <p>{item.city}</p>
                                        <p>{item.region}</p>
                                        <p className={item.contact.whatsapp === '' && 'd-none'}>Whatsapp +{ item.contact.whatsapp }</p>
                                    </div>
                                  </div>
                                ))}
                        </div>
                      </div>
                      <div className="col-lg-4 offset-xxl-1 pl-lg-4 order-4 order-lg-3">
                        <div className="item-contact row mb0">
                          <h5  className="col-6 col-lg-12">{realEstate?.sections?.footer?.title_social}:</h5>
                          <div className="col-6 col-lg-12">
                            <a target="_blank" href={realEstate.social.instagram} className={realEstate.social.instagram ? "d-inline" : 'd-none'}><i className="icon-instagram"></i></a>
                            <a target="_blank" href={realEstate.social.facebook} className={realEstate.social.facebook ? "d-inline" : 'd-none'}><i className="icon-facebook"></i></a>
                            <a target="_blank" href={realEstate.social.linkedin} className={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i className="icon-linkedin"></i></a>
                            <a target="_blank" href={realEstate.social.youtube} className={realEstate.social.youtube ? "d-inline" : 'd-none'}><i className="icon-youtube"></i></a>
                            <a target="_blank" href={realEstate.social.twitter} className={realEstate.social.twitter ? "d-inline" : 'd-none'}><i className="icon-twitter"></i></a>
                            <a target="_blank" href={realEstate.social.tiktok} className={realEstate.social.tiktok ? "d-inline" : 'd-none'}><i className="icon-tiktok"></i></a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 div_btn_footer d-none d-lg-block order-lg-4">
                        <div>
                          <Link className="d-none d-lg-none btn btn-primary mt-5" to={realEstate?.sections?.footer?.button_contact.link}>{realEstate?.sections?.footer?.button_contact.value}</Link>
                          <Link className="btn btn-primary d-lg-inline-block d-none mt-0" to={realEstate?.sections?.footer?.button_contact?.link}>{realEstate?.sections?.footer.button_contact.value}</Link>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xxl-3 order-3 order-lg-5">
                        <div className="item-contact row">
                          <h5 className="col-6 col-lg-12">{realEstate.sections.footer.title_matricula}:</h5>
                          <p className="col-6 col-lg-12">{realEstate.global_broker_id[0]} <br/> {realEstate.global_broker_id[1]}</p>    
                          {/* <div className="col-6 offset-6 col-lg-12 offset-lg-0 div-logos d-flex">
                            <img src={logo1} alt="CIA" />
                            <img src={logo2} alt="SOM" />
                          </div> */}
                        </div>
                      </div>
                      <div className="col-lg-4 offset-xxl-1 order-5 order-lg-6">
                        <div className="content-contact mt-auto">
                          <h4>{realEstate.sections?.footer?.title_newsletter}:</h4>
                          <form onSubmit={sendForm} id="formNewsletter" >
                            <div className="d-flex mb-lg-4">
                                <input type="email" onChange={handleInputChange} name="email" required placeholder="Ingresá tu email" className="mr-2" />
                                <button
                                disabled={disabled}
                                type="submit" className="submit icon-arrow-right"></button>
                            </div>
                            {
                                realEstate?.keys?.captcha ? 
                                <ReCAPTCHA
                                sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                                onChange ={() => setDisabled(false)}
                                /> 
                                : 
                                '' 
                            }
                          </form>
                        </div>
                      </div>
                    </div>
                </div>
                {/* <div className="col-lg-4 div_btn_footer d-none d-lg-flex">
                  <div>
                    <Link className="d-none d-lg-none btn btn-primary mt-5" to={realEstate?.sections?.footer?.button_contact.link}>{realEstate?.sections?.footer?.button_contact.value}</Link>
                    <Link className="btn btn-primary d-lg-inline-block d-none mt-5" to={realEstate?.sections?.footer?.button_contact?.link}>{realEstate?.sections?.footer.button_contact.value}</Link>
                  </div>
                </div> */}
                {/* <div className="col-lg-4 col-xxl-3 ">
                  <div className="item-contact row">
                    <h5 className="col-6 col-lg-12">{realEstate.sections.footer.title_matricula}:</h5>
                    <p className="col-6 col-lg-12">CUCICBA Nº 2696 / som</p>    
                  </div>
                </div> */}
                {/* <div className="col-lg-4 offset-xxl-1">
                    <div className="content-contact mt-auto">
                      <h4 >{realEstate.sections?.footer?.title_newsletter}</h4>
                      <form onSubmit={sendForm} id="formNewsletter" >
                        <div className="d-flex mb-lg-4">
                            <input type="email" onChange={handleInputChange} name="email" required placeholder="Ingresá tu mail" className="mr-2" />
                            <button
                             disabled={disabled}
                            type="submit" className="submit icon-arrow-right"></button>
                        </div>
                        {
                            realEstate?.keys?.captcha ? 
                            <ReCAPTCHA
                            sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                            onChange ={() => setDisabled(false)}
                            /> 
                            : 
                            '' 
                        }
                      </form>
                    </div>
                </div> */}
               
              </div>
            </div>      
        </div>
        <footer>
            <div className="container-fluid">
                <div className="row row-nav">
                    <div className="col-12 col-lg-2 d-flex d-lg-block align-items-center flex-column nav-footer-logo">
                      <div className="logo-footer d-none d-lg-flex">
                        {/* <Link to="/"><img src={logo}></img></Link>  */}
                        <Link to="/"><LogoFooterSVG className="logo-footer-svg"/></Link> 
                        {/* CAMBIAR POR REALESTATE.LOGO */}
                      </div>
                        <div className="d-block d-lg-none mt-auto">
                           {
                            realEstate?.fiscal_data ? 
                              <a className="qr d-flex justify-content-start" href={realEstate?.fiscal_data} target="_F960AFIPInfo"><img src={"http://www.afip.gob.ar/images/f960/DATAWEB.jpg"} border="0" /></a>
                              
                            :
                              ''
                          }
                            <div className="d-flex align-items-start justify-content-center text-center">
                            <p className="copy text-center">
                              Promoted by <a href="https://mediahaus.com.ar"> Mediahaus</a> - Powered by <a href="https://mediacore.app/"> MediaCore</a><br />
                              <span style={{fontSize: "0.7rem"}} >Todos los derechos reservados ® {getCurrentYear()}</span>
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-lg-9 offset-lg-1 d-lg-flex align-items-center justify-content-between nav-footer footer-links-wrapper">
                        {makeItemsNav(developments,filters,novelties)
                          .filter(nav => nav.name !== 'FAVORITOS/COMPARAR')
                          .map((item,index) => (
                          <li className={(pathname === item.link.replaceAll("/","").toLowerCase() ? 'active ' : '')} key={index}>
                            { (item.name === 'LOC. COMERCIALES / OFICINAS'
                            || item.name === 'VIVIENDAS'
                            || item.name === 'TERRENOS') 
                              ? (<a onClick={() => staticBody() + setOpen(false)} 
                                  className="conditional-menu-footer-link" href={item.link}>
                                  {item.name}
                                </a>) 
                              : (<Link onClick={() => staticBody() + setOpen(false)} 
                                  className="default-menu-footer-link" to={item.link}>
                                  {item.name}
                                </Link>) }
                          </li>
                        ))}
                        <li><Link className="me-lg-4 icon-favorites d-flex align-items-center justify-content-center" to={"/favoritos"}><i className={"icon-like " + (location.pathname.includes("favoritos") ? 'active' : '')}></i> </Link></li>
                    </div>
                </div>
                {/* desktop */}
                <div className={"row mt-lg-5 py-lg-3 d-none d-lg-flex align-items-end" + (realEstate?.fiscal_data ? " justify-content-between" : " justify-content-center")}>
                  <div className={"d-lg-flex" + (realEstate?.fiscal_data ? " col-lg-7 col-6 align-items-start mt-lg-5" : " col-12 align-items-center justify-content-center text-center")}>
                      <p className={"copy" + (realEstate?.fiscal_data ? " mt-lg-5" : "")}>
                        Promoted by <a href="https://mediahaus.com.ar" target="_blank"> Mediahaus</a> - Powered by <a target="_blank" href="https://mediacore.app/"> MediaCore</a><br />
                        <span style={{fontSize: "0.7rem"}} >Todos los derechos reservados ® {getCurrentYear()}</span>
                      </p>
                  </div>
                  { realEstate?.fiscal_data ?
                    <div className="col-lg-2">
                      <a className="qr d-flex justify-content-end" href={realEstate?.fiscal_data} target="_F960AFIPInfo"><img src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" /></a>
                    </div> : '' }
                </div>
            </div>
        </footer>
      </>
    ):''
}

export default connect(state => ({
  filters:state.properties.totalListFilters,
  api_key:state.settings.keys.tokko,
  developments:state.developments.developments,
  novelties:state.novelties.novelties,
  property:state.properties.property,
  development:state.developments.development
}),null)(Footer);