import { Link } from "gatsby"
import React, {useState} from "react"

// import LOGO_NORMAL from "../images/svg/laura-farias-logo.svg";
// import LOGO_WHITE from "../images/svg/laura-farias-logo-white.svg";

import $ from 'jquery'
import NavMenu from "./menu-nav";
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from "@reach/router";
import { useEffect } from "react";
import {cleanDevelopmentAction} from "../redux/developmentsDucks";
import {clearPropertyAction, getAllLocationsAction} from "../redux/propertiesDucks";
import ReactGA from "react-ga4";
import {getfavorites} from '../helpers/helper.favorites'

import { connect } from "react-redux";
import LogoHeaderSVG from "../images/logo-header-girard.svg"

function Main({dispatch, settings ,updaterFavorites, api_key, allLocations,siteTitle, logo}) {

    const [open,setOpen] = useState(false);
    const location = useLocation()

    const [countFavorites,setCountFavorites] = useState(getfavorites("prop",settings.short_name).length)

    useEffect(() => {
        if(!location.pathname.includes('/emprendimientos')){
            dispatch(cleanDevelopmentAction())
        }
        if(!location.pathname.includes('/propiedad')){
            dispatch(clearPropertyAction())
        }
    },[location])

    useEffect(() => {
        if(api_key && !allLocations?.locations){
            dispatch(getAllLocationsAction())
        }
    },[api_key,allLocations])

    useEffect(() => {
        ReactGA.initialize("G-R5BG3XNQ8X");
    })

    const staticBody = () => {
        var body = document.body;
        body.classList.toggle("overflow-hidden");
    }

    useEffect(() => {
        setCountFavorites(getfavorites("prop",settings.short_name).length)
        $("#count_favorites").removeClass("animate__heartBeat");
        $("#count_favorites_mobile").removeClass("animate__heartBeat");
        setTimeout(function(){
            $("#count_favorites").addClass("animate__heartBeat");
            $("#count_favorites_mobile").addClass("animate__heartBeat");
        }, 100);
        // $("#count_favorites").toggleClass("animate__heartBeat");
    },[updaterFavorites])


    return(
    <header id="header" className={"blur " + (open ? 'opened' : '')}>
        <Toaster
        toastOptions={{
            className: 'toast-className',
            style: {
                maxWidth: 'fit-content',
                
              },
        }} />
        <nav className="brand">
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div className={"col-3 d-flex d-lg-none align-items-center justify-content-lg-center "}>
                            <Link className="d-flex icon-favorites" to={"/favoritos"}><i className={`icon-favoritos-heart ` + (location.pathname.includes("favoritos") ? 'active' : '')}></i>
                                <span id="count_favorites-mobile" className={'font-global ms-2 d-block d-lg-none ' + " animate__animated " }>{countFavorites}</span> 
                            </Link>
                            {/* {(
                                countFavorites === 0
                                ?  <div onClick={() => toast.error("No tienes Propiedades favoritas seleccionadas.")} className="compare-link ms-4">
                                        COMPARAR
                                    </div>  
                                :   <Link to="/favoritos?comparar" className="compare-link ms-4">
                                        COMPARAR
                                    </Link>  
                            )} */}
                            
                        </div>
                    <div className="col-lg-7 col-6 d-flex justify-content-center justify-content-lg-start">
                        <Link href={"/"} className="logo d-none d-lg-block">
                            {/* <LOGO_NORMAL className="img color" />
                            <LOGO_WHITE className="img white" /> */}
                            {/* <img className="logo-header" src={logoHeader}></img> */}
                            <LogoHeaderSVG className="logo-header"/>
                        </Link>
                        <Link href={"/"}  className="logo d-lg-none">
                            {/* <LOGO_NORMAL className="img color" />
                            <LOGO_WHITE className="img white" /> */}
                            {/* <img className="logo-header" src={logoHeader}></img> */}
                            <LogoHeaderSVG className="logo-header"/>
                        </Link>
                    </div>
                    <div className="col-lg-5 col-3 text-right d-flex align-items-center justify-content-end">
                            <div className={"me-4 " + (true ? "d-lg-block d-none" : 'd-none')}>
                                {(
                                    countFavorites === 0
                                    ?  <div style={{color:"#c4c4c4"}} onClick={() => toast.error("No tienes Propiedades favoritas seleccionadas.")} className="compare-link ms-4 fs-6">
                                            COMPARAR
                                        </div>  
                                    :   (countFavorites === 1
                                        ?   <div style={{color:"#c4c4c4"}} onClick={() => toast.error("Tienes que tener al menos 2 propiedades en favoritos para poder comparar")} className="compare-link ms-4 fs-6">
                                                COMPARAR
                                            </div> 
                                        :   <Link to="/favoritos?comparar" className="compare-link ms-4 fs-6">
                                                COMPARAR
                                            </Link>
                                        )  
                                )}
                            </div>
                            {
                                (
                                countFavorites === 0
                                ?   <div className="hover-like d-lg-flex d-none align-items-center" onClick={() => toast.error("No tienes Propiedades favoritas seleccionadas.")} >
                                        <i className={`me-4 icon-like `  + (countFavorites < 1 && ' disabled ') + (location.pathname.includes("favoritos") ? ' active ' : '')}></i>
                                    </div> 
                                :   <div className={"hover-like d-lg-flex d-none align-items-center "}>
                                        <span id="count_favorites" className={'' + (!countFavorites ? ' d-none ' : ' d-none d-lg-block ') + " animate__animated " }>{countFavorites}</span> 
                                        <Link className="me-4 icon-favorites d-none d-lg-flex align-items-center justify-content-center" to={"/favoritos"}><i className={`icon-like `  + (countFavorites < 1 && ' disabled ') + (location.pathname.includes("favoritos") ? ' active ' : '')}></i></Link>
                                    </div>   
                                )
                            }
                            
                            <button id="toggle-nav" onClick={() => setOpen(!open) + staticBody()}>
                                <div className="d-flex align-items-center">
                                    <span className="status_nav d-none d-lg-inline fs-6">MENÚ </span>
                                    <span className="ms-4"><i></i><i></i><i></i></span>
                                </div>
                            </button>
                        </div>
                </div>
            </div>
        </nav>
        <NavMenu setOpen={setOpen} />
  </header>
)}

export default connect(state => ({
    settings: state.settings,
    api_key: state.settings.keys.tokko,
    allLocations:state.properties.allLocationFilters,
    updaterFavorites:state.properties.updaterFavorites
}),null)(Main);