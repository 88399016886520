import { useLocation } from '@reach/router';
import React, { useEffect } from 'react'
import { useRef, useState } from "react";
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
  } from "react-share";
  import {connect} from 'react-redux'
import { getFakeAddres } from '../helpers/helper.properties';
import { getName } from '../helpers/helper.developments';

const SocialModal = ({property,development}) => {

    const location = useLocation()
    const [data,setData] = useState()
    const [title,setTitle] = useState()
    const url = location?.href

    useEffect(() => {
        const title = 
            "Te comparto " + 
            (location?.href?.includes("emprendimiento") ? 'el siguiente emprendimiento: ' : 'la siguiente propiedad: ') + 
            (location?.href?.includes("venta") ? 'en venta: ' :'') +
            (location?.href?.includes("temporario") ? 'en alquiler temporario: ' : (url?.includes("alquiler") ? 'en alquiler' : '')) + 
            (location?.href?.includes("emprendimiento") ? '' : getFakeAddres(data))
        setTitle(title)
        setData(location?.href?.includes("emprendimientos") ? development : property)
    },[property,development,location])

    console.log(location)
    console.log(data)

    return data?.id ? (
        <div class="modal fade social-modal" id={"modal-social-"+ data.id} tabindex="1066" aria-hidden="true">
            <div class="modal-dialog modal-dialog modal-dialog-centered justify-content-center">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Compartilo en tus redes sociales</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body align-items-center d-flex justify-content-center">
                        <div className="d-flex justify-content-center align-items-center">
                            <WhatsappShareButton
                                className="mx-1"
                                url={url}
                                title={title}
                                separator=": "
                            >
                                <WhatsappIcon size={38} round />
                            </WhatsappShareButton>
                            <EmailShareButton 
                                className="mx-1"
                                body={title + " " + url} 
                                subject={getFakeAddres(data)} >
                                <EmailIcon size={38} round />
                            </ EmailShareButton>
                            <FacebookMessengerShareButton
                                className="mx-1"
                                url = {url}
                                appId="521270401588372" >
                                <FacebookMessengerIcon size={38} round />
                            </FacebookMessengerShareButton>
                            <TelegramShareButton
                                className="mx-1"
                                url={url}
                                title={title}>
                                <TelegramIcon size={38} round />
                            </TelegramShareButton>
                            <FacebookShareButton
                                className="mx-1"
                                url = {url}
                                quote={title}>
                                <FacebookIcon size={38} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                className="mx-1"
                                url={url}
                                title={title}
                            >
                                <TwitterIcon size={38} round />
                            </TwitterShareButton>
                            <LinkedinShareButton 
                                url={url} 
                                source={url} 
                                title={title}
                                className="mx-1">
                                <LinkedinIcon size={38} round />
                            </LinkedinShareButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ):''
}

export default connect(state => ({
    property: state.properties.property,
    development: state.developments.development,
}),null)(SocialModal);