import {Link } from 'gatsby';
import React, {useEffect} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import {connect} from 'react-redux';

import {getTotalListFilters} from '../redux/propertiesDucks';
import {getDevelopmentsAction} from '../redux/developmentsDucks';

import {existOperation,existDevelopment, makeItemsNav, getMessageWhatsapp} from '../helpers/helper.rendering'
import {getOperationView} from '../helpers/helper.filters'



import { getNoveltiesAction } from '../redux/noveltiesDucks';
import { element } from 'prop-types';
import { getActionsAction } from '../redux/actionsDucks'; 
// import logo1 from "../images/dark-CIA.png"
// import logo2 from "../images/SOM.png"

const MenuNav = ({filters,curFilters,api_key,developments,novelties,dispatch,setOpen}) => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
            global_phone
            global_email
            global_whatsapp
            global_broker_id
            attention
              social{
                facebook
                instagram
                linkedin
                youtube
                twitter
                tiktok
              }
              branch_office{
                name
                address
                address_note
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              env { 
                APISERVER
                TENANTID
              }
              sections {
                  footer {
                      title_contact
                      title_social
                      title_newsletter
                      title_office
                      title_matricula
                      button_contact{
                          link
                          value
                      }
                  }
              }
          }
    }`)
    const { design: env } = realEstate
    
    const location = useLocation()
    const pathname = location.pathname.split("/")[1]

    useEffect(() => {
        if(api_key){
            dispatch(getDevelopmentsAction())
            dispatch(getTotalListFilters())
        }
    }, [api_key])
    
    useEffect(() => {
        dispatch(getNoveltiesAction(true))
        dispatch(getActionsAction(env))
    },[])

    const staticBody = () => {
        var body = document.body;
        body.classList.remove("overflow-hidden");
    }

    return realEstate.sections ? (
        <nav className="nav-content opened d-flex align-items-center">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 pe-xl-5 nav-wrapper">
                        <ul className="nav-menu">
                            {makeItemsNav(developments,filters,novelties).map((item,index) => (
                                <li key={index}
                                    className={(
                                        ((pathname === item.link.replaceAll("/","").toLowerCase())
                                        || (location.pathname === item.link)) 
                                            ? 'active ' : '')} >
                                    { (item.name === 'LOC. COMERCIALES / OFICINAS'
                                    || item.name === 'VIVIENDAS'
                                    || item.name === 'TERRENOS')
                                        ? (<a onClick={() => staticBody() + setOpen(false)} 
                                            className="menu-item-link conditional-menu-link" href={item.link}>
                                            {item.name.toLowerCase()}
                                          </a>)
                                        : (<Link onClick={() => staticBody() + setOpen(false)} 
                                              className="menu-item-link default-menu-link" to={item.link}>
                                              {item.name.toLowerCase()}
                                            </Link>) }
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-4 px-xl-5 contact-nav d-none d-lg-block scroll-container">
                        <div className="row">
                            <div className="item-contact col-8 col-lg-12">
                                <h5>{realEstate.sections.footer.title_contact}:</h5>
                                <div className="d-none d-lg-block col-12">
                                    {/* <h5 className='subtitle mb-0'>Central</h5> */}
                                    <a className="hover-service-link hover-link" target="_blank" 
                                    href={`mailto:${realEstate.global_email}`}>{realEstate.global_email}</a>
                                    <p>Tel. {realEstate.global_phone[0]}</p>
                                    <p>Whatsapp <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp() + "&phone=" + realEstate.global_whatsapp.replaceAll('-','').replaceAll(' ','')} target="_blank" className="hover-service-link hover-link d-inline">{realEstate.global_whatsapp}</a></p>
                                </div>
                            </div>
                            <div class="item-contact col-8 col-lg-12">
                                <h5>ENCONTRAN0S:</h5>
                                {realEstate.branch_office.length > 0 &&
                                    realEstate.branch_office.map((item,index) => (
                                    <div className="d-none d-lg-block col-12" key={index}>
                                        {/* <h5 className="subtitle mb-0">{item.name}:</h5> */}
                                        <div className="d-block">
                                            <p>{item.address} {item.address_note ? <span className="note">{item.address_note}</span> : null}</p>
                                            <p>{item.city}</p>
                                            <p>{item.region}</p>
                                            <p className={item.contact.whatsapp === '' && 'd-none'}>Whatsapp +{ item.contact.whatsapp }</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div class="item-contact col-8 col-lg-12">
                                <h5>{realEstate.sections.footer.title_matricula}:</h5>
                                <p>{realEstate.global_broker_id[0]} <br/> {realEstate.global_broker_id[1]}</p>
                                {/* <div className="col-6 offset-6 col-lg-12 offset-lg-0 div-logos d-flex">
                                    <img src={logo1} alt="CIA" className="cia"/>
                                    <img src={logo2} alt="SOM" className="som"/>
                                </div> */}
                            </div>
                            <div class="item-contact social col-4 col-lg-12">
                                <h5>SEGUINOS:</h5> 
                                <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class="icon-instagram"></i></a>
                                <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class="icon-facebook"></i></a>
                                <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class="icon-linkedin"></i></a>
                                <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class="icon-youtube"></i></a>
                                <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class="icon-twitter"></i></a>
                                <a target="_blank" href={realEstate.social.tiktok} class={realEstate.social.tiktok ? "d-inline" : 'd-none'}><i class="icon-tiktok"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    ):''
}

export default connect(state => ({
    filters:state.properties.totalListFilters,
    curFilters:state.properties.filters,
    novelties:state.novelties.novelties,
    api_key:state.settings.keys.tokko,
    developments:state.developments.developments
}),null)(MenuNav);