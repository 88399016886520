import React, { useEffect }  from 'react';
import { useLocation } from '@reach/router'
import Countdown from 'react-countdown';
import { informCustomEvent } from '../../../../helpers/helper.analytics';

// const TopAlert = ({linkTo, linkTxt, textAlert, showOn, eventDate}) => {
// const TopAlert = ({linkTo, linkTxt, textAlert, showOn, eventDate, utmValue}) => {
const TopAlert = ({linkTo, linkTxt, textAlert, showOn, eventDate, utmValue,small_type,small_section,name}) => {

    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','')
    
    useEffect(() => {
        if(checkPage()) {
            informCustomEvent("SHOW_" + small_section + "_" + small_type + "_" + name)
            setTimeout(() => {
                document.getElementById("top-alert")
                    .classList.toggle("animate-show")
            }, 1000)
        }
    }, [pathname,showOn])

    // Check where shows
    const checkPage = () => {
        if(pathname === '' && showOn.toLowerCase() === 'inicio'){return true}
        if(pathname.toLowerCase().includes('emprendimientos') && pathname.length > 18 && showOn.toLowerCase() === 'ficha de emprendimiento'){console.log("true"); return true}
        if(pathname.toLowerCase().includes('propiedad') && showOn.toLowerCase() === 'ficha de la propiedad'){console.log("true"); return true}
        if(pathname.toLowerCase() === 'venta' && showOn.toLowerCase() === 'resultados venta'){console.log("true"); return true}
        if(pathname.toLowerCase() === 'alquiler' && showOn.toLowerCase() === 'resultados alquiler'){console.log("true"); return true}
        if(pathname.toLowerCase() === 'alquiler-temporario' && showOn.toLowerCase() === 'resultados alquiler temporario'){console.log("true"); return true}
        if(pathname.toLowerCase() === 'servicios' && showOn.toLowerCase() === 'tasaciones'){return true}
        return (Array.isArray(showOn)) 
            ?  showOn.toLowerCase().includes(pathname.toLowerCase())
            : ( (pathname.toLowerCase() === showOn.toLowerCase()) || false )
    }

    // Render countdown
    const rendererCtd = ({ days, hours, minutes, seconds }) =>
        <div className="counter d-flex">
            <span className="mx-2">
                <span className="date-val">{days}</span> DÍAS</span>
            <span className="me-2">
                <span className="date-val">{hours}</span> HS</span>
            <span className="me-2">
                <span className="date-val">{minutes}</span> MIN</span>
            <span className="me-2">
                <span className="date-val">{seconds}</span> SEG</span>
        </div>

    const capitalizeFirst = (text) =>
        text[0].toUpperCase() + text.slice(1, text.length)

    return checkPage() ? (
        <div id={"top-alert"} className={"alert alert-dismissible fade show " + (showOn.toLowerCase() === 'inicio' ? "isHome" : '')} role="alert">
            <div className="container-fluid">
                {/* Counter */}
                { eventDate &&
                    <div className="counter-wrapper">
                        Quedan 
                        <Countdown 
                            date={new Date(eventDate).getTime()} 
                            renderer={rendererCtd}/>
                    </div> }
                {/* Text */}
                { textAlert && 
                    <div className={`text-content my-2`}>
                        { (eventDate) ? ` ${textAlert}` : capitalizeFirst(textAlert) }
                    </div> }
                {/* Link */}
                { (linkTo && linkTxt) &&
                    // <a href={linkTo} className="btn-link" target="_blank">
                    //     {linkTxt}
                    // </a> }
                    <a href={linkTo + utmValue} onClick={() => informCustomEvent("PUSH_" + small_section + "_" + small_type + "_" + name)} className="btn-link" target="_blank">
                        {linkTxt}
                    </a> }

            </div>
            {/* X close button */}
            <i className="icon-plus" type="button" role="button" id="closeAlert"
                data-bs-dismiss="alert" aria-label="Close"></i>
        </div>
    ):
    null
}

export default TopAlert
