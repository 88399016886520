import { useLocation } from '@reach/router';
import React, { useEffect } from 'react';
import { getCodes } from '../../../../helpers/helper.actions';
import { informCustomEvent } from '../../../../helpers/helper.analytics';

const FlapBtn = ({alignment="left", linkTo, linkTxt, showOn,type,section,small_type,small_section,name}) => {
    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','')

    const checkPage = () => {
        if(pathname === '' && showOn.toLowerCase() === 'inicio'){return true}
        if(pathname.toLowerCase().includes('emprendimientos') && pathname.length > 18 && showOn.toLowerCase() === 'ficha de emprendimiento'){console.log("true"); return true}
        if(pathname.toLowerCase().includes('propiedad') && showOn.toLowerCase() === 'ficha de la propiedad'){console.log("true"); return true}
        if(pathname.toLowerCase() === 'venta' && showOn.toLowerCase() === 'resultados venta'){console.log("true"); return true}
        if(pathname.toLowerCase() === 'alquiler' && showOn.toLowerCase() === 'resultados alquiler'){console.log("true"); return true}
        if(pathname.toLowerCase() === 'alquiler-temporario' && showOn.toLowerCase() === 'resultados alquiler temporario'){console.log("true"); return true}
        if(pathname.toLowerCase() === 'servicios' && showOn.toLowerCase() === 'tasaciones'){return true}
        return (Array.isArray(showOn)) 
            ?  showOn.toLowerCase().includes(pathname.toLowerCase())
            : ( (pathname.toLowerCase() === showOn.toLowerCase()) || false )
    }

    useEffect(() => {
        if(checkPage()){
            informCustomEvent("SHOW_" + getCodes(type, showOn) + "_" + name)
        }
    },[])

    return checkPage() && (
        <div id="flap-btn" className={`fixed-btn-wrapper ${alignment}`}>
            <a href={linkTo} onClick={() => informCustomEvent("PUSH_" + small_section + "_" + small_type + "_" + name)} className="btn-link" target="_blank">
                {linkTxt}
            </a>
        </div>
    )
}

export default FlapBtn
